import React, { Component, createRef } from 'react';
import consumer from '../channels/consumer';
import { ResultReason, SpeechConfig, SpeechSynthesizer, AudioConfig, SpeechRecognizer, AudioOutputStream } from 'microsoft-cognitiveservices-speech-sdk';

const speechsdk = require('microsoft-cognitiveservices-speech-sdk');

interface State {
  // 登录信息
  login_avatar: string;
  signin: number;

  // 原xiaolan
  // 切换是对话模式还是显示初始使用说明
  conversaion: number;

  // 安防
  security_all: number;
  water_safe: number;
  smoke_safe: number;
  security_safe: number;
  space_human_count: number;

  // 环境
  temperature: number;
  humidity: number;
  pm25: number;
  co2: number;

  // 对话状态
  listen_button_state: number;
  bot_speaking: number;
  status: string;
  displayText: string;
  simulatedText: string;

  // 是否是健身房
  is_gym: number;
  // 健身房相关业务
  gym_cup: number;
  // 健身房本周来过人数
  gym_visit_number: number;
  // 平均健身小时
  gym_avg_hour: number;
  // 用户健身小时
  gym_user_hour: number;
  // 排名
  gym_rank: number;
  // gym welcome
  gym_welcome: string;

  // 信用(登录后)
  credit_score: string;
  credit_title: string;

}

export default class Index extends Component<{}, State> {
  btnRef: React.RefObject<HTMLButtonElement>;
  tokenObj: { authToken: string | null; region: string };
  cable: any;
  subscription: any;
  roomId: string | null;
  content: string;
  speechConfig: SpeechConfig | undefined;
  synthesizer: SpeechSynthesizer | undefined;
  recognizer: SpeechRecognizer | undefined;
  audioContext: AudioContext | undefined;
  silenceSource: AudioBufferSourceNode | undefined;

  constructor(props: {}) {
    super(props);
    this.btnRef = createRef<HTMLButtonElement>();
    this.startRecording = this.startRecording.bind(this);
    this.stopRecording = this.stopRecording.bind(this);
    this.simulateSpeechInput = this.simulateSpeechInput.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = {
      gym_visit_number: 0,
      conversaion: 1,
      signin: 1,
      is_gym: 1,
      gym_cup: 3.5,
      security_all: 1,
      water_safe: 1,
      smoke_safe: 1,
      security_safe: 1,
      temperature: 27.0,
      humidity: 27,
      pm25: 22,
      co2: 234,
      login_avatar: "https://cdn.axspace.cn/desk/images/bg.png",
      listen_button_state: 0,
      bot_speaking: 0,
      // 初始化新增的状态
      status: "init",
      displayText: '努力加载中',
      simulatedText: '',
      credit_score: '800+',
      credit_title: '社区精英',
      space_human_count: 1,
    };
    this.tokenObj = { authToken: null, region: '' };
    this.cable = null;
    this.subscription = null;
    this.roomId = null;
    this.content = '';
  }

  async componentDidMount() {
    
    this.tokenObj = await getTokenOrRefresh();
    (window as any).talkComponent = this;
    window.addEventListener('keydown', this.handleKeyDown);
    (window as any).handleRecognizedText = this.simulateSpeechInput.bind(this);
    (window as any).setStateReady = this.setStateReady.bind(this);
    (window as any).setTalkState = this.setTalkState.bind(this);
    window.setInitState();
    (window as any).handleNoMatch = function () {
      console.log("No speech recognized.");
      this.setState({
        displayText: '没听清楚，请再说一遍',
        status: "recognize_failed"
      });
    }.bind(this);

    (window as any).handleCanceled = function (errorDetails: any) {
      console.log("Recognition canceled:", errorDetails);
      // Handle cancellation
    }.bind(this);

    const roomIdElement = document.getElementById('room_id');
    const roomId = roomIdElement ? roomIdElement.textContent : null;
    this.roomId = roomId;
    if (this.tokenObj.authToken === null) {
      this.setState({
        displayText: "出错了，请重新进入页面试试",
        status: "error"
      });
    } else {
      this.setState({
        displayText: '按一下说话',
        status: "ready",
      });
    }
    this.cable = consumer;
    this.subscription = this.cable.subscriptions.create(
      { channel: 'RoomMessageChannel', room_id: this.roomId },
      { received: this.roomMessageListener.bind(this) }
    );
    // // 初始化 Pusher
    // (window as any).Pusher.logToConsole = true; // 可以在控制台查看日志，便于调试
    // const pusher = new (window as any).Pusher("ceea1a93623f02506d45", {
    //   cluster: "ap3",
    //   encrypted: true,
    //   // authEndpoint: 'http://localhost:3000/pusher_auth'
    //   authEndpoint: 'https://voice.axspace.cn/pusher_auth'
    // });

    // 订阅私有通道
    // const channel = pusher.subscribe('private-my-channel');
    // let pusherData: any = {};

    // // 订阅 channel 的消息
    // channel.bind("client-message", (data: any) => {
    //   console.log(`<< [${data.id}] ${data.message}`);
    //   pusherData = data;
    //   console.log('pusherData', data);
    //   let eventNameNo = "client-message-no";
    //   let dataNo = { message: `不支持此操作` };
    //   if (data.type === 'voice-start') {
    //     this.startRecording();
    //     dataNo = { message: `执行成功` };
    //   } else if (data.type === 'voice-stop') {
    //     this.stopRecording();
    //     dataNo = { message: `执行成功` };
    //   } else if (data.type === 'voice-reload') {
    //     window.location.reload();
    //     dataNo = { message: `执行成功` };
    //   } else if (data.type === 'voice-jump') {
    //     window.location.href = "http://tsingarchitech.com/plat";
    //     dataNo = { message: `执行成功` };
    //   }
    //   channel.trigger(eventNameNo, dataNo);
    // });
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 's') {
      this.startRecording();
    } else if (event.key === 'e') {
      this.stopRecording();
    } else if (event.key === 'b') {
      window.location.href = "http://tsingarchitech.com/plat";
    } else if (event.key === 'r') {
      window.location.href = "http://voice.axspace.cn/guest0922";
    } else if (event.key === 'q') {
      this.playMutedAudio();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    this.cable.subscriptions.remove(this.subscription);
  }

  roomMessageListener(data: any) {
    // TODO: 在这里定义更多根据websocket更改页面的逻辑
    console.log(data);
    if (data.type === 'update_text') {
      this.content = data.content;
      if (this.content.length === 0) {
        return;
      }
      // (document.getElementById('talk-author-name') as HTMLElement).innerHTML = data.user;
      // (document.getElementById('talk-content') as HTMLElement).innerHTML = data.content;

      // Set status only when first received
      if (this.state.status !== 'send_to_tts') {
        this.setState({
          status: 'send_to_tts',
          displayText: "合成中..."
        });
      }

      // If Android interface is available, send text to Android
      if (typeof (window as any).Android !== 'undefined' && (window as any).Android.sendTextToAndroid) {
        (window as any).Android.sendTextToAndroid(this.content);
      } else {
        // Web page plays sound
        if (data.final === true) {
          this.send_to_tts(this.content);
        }
      }

    } else if (data.type === 'login') {
      if (data.user_info.room === 'gym' && this.state.is_gym === 1) {
        this.setState({
          //     params.require(:room)
          login_avatar: data.user_info.user_avatar_url,
          // nickname: data.user_nick_name,
          // wechat_user_id: data.wechat_union_id,
          signin: 1
        });
      } else {
        console.log('room not match! room=' + data.user_info.room + ' is_gym=' + this.state.is_gym);
      }
    } else {
      console.log('unknown type in roomMessageListener');
    }
  }

  simulateSpeechInput(text: string) {
    console.log('Simulate speech input:', text);
    this.setState({ simulatedText: text }, () => {
      // 调用识别逻辑来处理模拟输入
      this.handleSimulatedSpeech();
    });
  }

  // 处理模拟语音输入
  handleSimulatedSpeech() {
    const result = {
      reason: ResultReason.RecognizedSpeech, // 强制设置为识别成功
      text: this.state.simulatedText
    };

    let displayText = `收到：${result.text}`;
    console.log(displayText);

    this.setState({
      displayText: displayText,
      status: "recognize_success"
    });

    // 操作 DOM，将识别到的文本显示到页面上
    // TODO 定义一个方法来修改
    // (document.getElementById('talk-author-name') as HTMLElement).innerHTML = '用户';
    // (document.getElementById('talk-content') as HTMLElement).innerHTML = result.text;

    this.sendTextToServer(result.text);
  }

  async send_to_tts(content: string) {
    this.init_synthesizer();
    this.synthesizer!.speakTextAsync(
      content,
      result => {
        if (result.reason === speechsdk.ResultReason.SynthesizingAudioCompleted) {
          (window as any).playVideoTalk();
          console.log("Synthesis finished for [" + content + "].");
          let source2 = this.audioContext!.createBufferSource();
          this.audioContext!.decodeAudioData(result.audioData, (buffer) => {
            source2.buffer = buffer;
            source2.connect(this.audioContext!.destination);
            this.silenceSource!.onended = () => {
              source2.start(0);
              this.setState({
                status: 'playing_audio',
                displayText: "讲话中..."
              });
            };

            source2.onended = () => {
              this.setState({
                status: 'ready',
                displayText: "按一下说话"
              });
              (window as any).playVideoMute();
              this.playMutedAudio();
            };
          });
        } else if (result.reason === speechsdk.ResultReason.Canceled) {
          console.log("Synthesis failed. Error detail: " + result.errorDetails);
        }
      },
      err => {
        console.log("Error: " + err);
      }
    );
  }

  testTTS() {
    if (typeof (window as any).Android !== 'undefined' && (window as any).Android.sendTextToAndroid) {
      (window as any).Android.sendTextToAndroid("你好");
    } else {
      console.log("Android interface not available.");
      // 如果 Android 接口不可用，可以使用网页的 TTS 进行测试
      this.send_to_tts("你好");
    }
  }

  playMutedAudio() {
    // 如果在安卓里，这个不该被调用
    if (typeof (window as any).Android !== 'undefined') {
      console.log("playMutedAudio should not be called in Android.");
      return;
    }
    if (this.audioContext === undefined) {
      this.audioContext = new AudioContext();
    }

    // 如果 audioContext 是 suspended 状态，尝试恢复
    if (this.audioContext.state === 'suspended') {
      this.audioContext.resume();
    }

    this.silenceSource = this.audioContext.createBufferSource();
    let silenceBuffer = this.audioContext.createBuffer(1, this.audioContext.sampleRate * 0.2, this.audioContext.sampleRate); // 创建一个无声buffer

    // 开始播放无声音频
    this.silenceSource.buffer = silenceBuffer;
    this.silenceSource.connect(this.audioContext.destination);
    this.silenceSource.start(0);
    this.silenceSource.onended = () => {
      this.playMutedAudio();
    };
  }

  startRecordingOld() {
    if (this.state.status === "ready") {
      console.log("mouse down - startRecording");
      this.sttFromMic();
    } else {
      console.log("ignore dup startRecording");
    }
  }

  stopRecordingOld() {
    if (this.state.status === "recording") {
      console.log("stopRecording");
      this.recognizer!.stopContinuousRecognitionAsync();
      this.setState({
        status: 'ready',
        displayText: "按一下说话"
      });
    }
  }

  startRecording() {
    if (this.state.status === "ready") {
      console.log("Start recording");
      if (typeof (window as any).Android !== 'undefined' && (window as any).Android.startRecording) {
        (window as any).Android.startRecording();
        this.setState({
          status: "recording",
          displayText: '聆听中，请说话'
        });
      } else {
        console.log("Android interface not available.");
        this.startRecordingOld();
      }
    } else {
      console.log("Recording already in progress or not ready.");
    }
  }

  stopRecording() {
    if (this.state.status === "recording") {
      console.log("Stop recording");
      if (typeof (window as any).Android !== 'undefined' && (window as any).Android.stopRecording) {
        (window as any).Android.stopRecording();
        this.setState({
          status: "ready",
          displayText: "按一下说话"
        });
      } else {
        console.log("Android interface not available.");
        this.stopRecordingOld();
      }
    }
  }

  setStateReady() {
    this.setState({
      status: 'ready',
      displayText: "按一下说话"
    });
  }

  setTalkState(state) {
    this.setState(state);
  }

  init_synthesizer() {
    if (this.synthesizer === undefined) {
      if (this.speechConfig === undefined) {
        this.speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(this.tokenObj.authToken!, this.tokenObj.region);
        this.speechConfig.speechRecognitionLanguage = 'zh-CN';
        this.speechConfig.speechSynthesisVoiceName = "zh-CN-XiaoxiaoNeural";
      }
      let stream = AudioOutputStream.createPullStream();
      const synthesizerAudioConfig = AudioConfig.fromStreamOutput(stream);
      this.synthesizer = new SpeechSynthesizer(this.speechConfig, synthesizerAudioConfig);
    }
  }

  init_recognizer() {
    if (this.speechConfig === undefined) {
      this.speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(this.tokenObj.authToken!, this.tokenObj.region);
      this.speechConfig.speechRecognitionLanguage = 'zh-CN';
      this.speechConfig.speechSynthesisVoiceName = "zh-CN-XiaoxiaoNeural";
    }
    const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
    this.recognizer = new SpeechRecognizer(this.speechConfig, audioConfig);
  }

  async sttFromMic() {
    console.log("sttFromMic");
    this.playMutedAudio();
    // 创建语音识别器对象

    this.init_recognizer();
    console.log('set recognizer');
    this.setState({
      status: "recording",
      displayText: '聆听中，请说话'
    });
    this.recognizer!.startContinuousRecognitionAsync();
    this.recognizer!.recognized = (s, e) => {
      let displayText;
      let status;
      let result = e.result;
      console.log(result);
      if (result.reason === ResultReason.RecognizedSpeech) {
        if (this.state.status !== "recording") {
          console.log("speech received not in recording, ignore it.");
          return;
        }
        displayText = `收到：${result.text}`;
        console.log(displayText);
        status = "recognize_success";
        // 原：操纵 DOM，把 ID = talk-author-name 的 innerHTML 改成 result.text
        // TODO 同上，修改成更好的更新文字的方案。
        // (document.getElementById('talk-author-name') as HTMLElement).innerHTML = '用户';
        // (document.getElementById('talk-content') as HTMLElement).innerHTML = result.text;
        this.sendTextToServer(result.text);
        this.recognizer!.stopContinuousRecognitionAsync();
      }
    };
  }

  async sendTextToServer(text: string) {
    console.log("sendTextToServer:", text);
    this.setState({
      displayText: "思考中...",
      status: "chat_to_server"
    });
    const roomId = this.roomId;
    const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
    const csrfToken = csrfTokenElement ? csrfTokenElement.getAttribute('content') : '';

    const params = new URLSearchParams();
    params.append('authenticity_token', csrfToken || '');
    params.append('message[content]', text);

    const response = await fetch(`/rooms/${roomId}/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'X-CSRF-Token': csrfToken || ''
      },
      body: params.toString(),
      credentials: 'include' // Important for sending cookies
    });

    if (!response.ok) {
      this.setState({
        displayText: "出错了，请重新进入页面试试",
        status: "error"
      });
    } else {
      this.setState({
        displayText: "思考中...",
        status: "receving_response"
      });
    }
  }

  shouldShowText() {
    return this.state.status === "init" ||
      this.state.status === "error" ||
      this.state.status === "recognize_success" ||
      this.state.status === "recognize_failed" ||
      this.state.status === "chat_to_server" ||
      this.state.status === "receving_response" ||
      this.state.status === "send_to_tts" ||
      this.state.status === "playing_audio";
  }

  shouldShowButton() {
    return this.state.status === "ready" || this.state.status === "recording";
  }

  showHideList() {
    const showStatuses = ["recording", "recognize_success", "recognize_failed", "chat_to_server", "receving_response", "send_to_tts", "playing_audio"];

    if (showStatuses.includes(this.state.status)) {
      if ((window as any).showTalkList) {
        (window as any).showTalkList();
      }
    } else {
      if ((window as any).hideTalkList) {
        (window as any).hideTalkList();
      }
    }
  }

  render() {
    const {
      gym_visit_number,
      conversaion,
      signin,
      is_gym,
      gym_cup,
      security_all,
      water_safe,
      smoke_safe,
      security_safe,
      temperature,
      humidity,
      pm25,
      co2,
      login_avatar,
      listen_button_state,
      bot_speaking,
      displayText,
      credit_score,
      credit_title,
      space_human_count,
    } = this.state;


    // 在这里调用 showHideList
    this.showHideList();

    // 根据状态显示不同的按钮文本

    return (
      <div className='index'>
        <div className='main-t'>
          <div className='main-t-l'>
            {is_gym ? (
              <div className='mian-info'>
                {(signin == 0) ? (
                  <div className='info-t' style={{ marginBottom: '10px' }}>
                    <span className='line-l'></span>
                    <span>晨光正好，运动正当时，为目标全力以赴！</span>
                    <span className='line-r'></span>
                  </div>
                ) : (
                  <div className='info-t'>
                    <div className='ranking-t'>
                      <span className='line-l'></span>
                      <span>第<span className='fsz24' style={{ margin: '0 18px' }}>2</span>名</span>
                      <span className='line-r'></span>
                    </div>
                    <div className='ranking-b'>您本周健身房排名</div>
                  </div>
                )}
                <div className='tac cup'>
                  {[
                    'https://cdn.axspace.cn/desk/images/icon_cup_full@2x.png',
                    'https://cdn.axspace.cn/desk/images/icon_cup_full@2x.png',
                    'https://cdn.axspace.cn/desk/images/icon_cup_full@2x.png',
                    'https://cdn.axspace.cn/desk/images/icon_cup_half@2x.png',
                    'https://cdn.axspace.cn/desk/images/icon_cup_gray@2x.png',
                  ].map((src, index) => (
                    <img
                      key={index}
                      style={{
                        width: '50px',
                        height: '50px',
                        marginRight: index < 4 ? '10px' : '0',
                      }}
                      src={src}
                      alt={`cup ${index}`}
                    />
                  ))}
                </div>
                <div className='cup-title'>
                  本周已有<span className='col-fff'>{gym_visit_number}人来过</span>，用户平均<span className='col-fff'>健身1.5小时</span>，加油！
                </div>
              </div>
            ) : (
              <div className='mian-info'>
                <div className='info-title'>
                  早安！新鲜的一天，从这里开始，选点温暖带上你的好心情！
                </div>
                <div className='info-data'>
                  <div className='info-l'>
                    <img
                      style={{
                        width: '96px',
                        height: '24px',
                        marginRight: '10px',
                      }}
                      src='https://cdn.axspace.cn/desk/images/icon_supermarket_on@2x.png'
                      alt='supermarket'
                    />
                  </div>
                  <div className='info-r'>
                    免费办理建行信用卡，享受超市95折优惠
                  </div>
                </div>
              </div>
            )}
            {signin ? (
              <div className='credit credit-signin'>
                <div className='credit-l'>
                  <div>
                    {[
                      'https://cdn.axspace.cn/desk/images/icon_credit_full@2x.png',
                      'https://cdn.axspace.cn/desk/images/icon_credit_full@2x.png',
                      'https://cdn.axspace.cn/desk/images/icon_credit_full@2x.png',
                      'https://cdn.axspace.cn/desk/images/icon_credit_half@2x.png',
                      'https://cdn.axspace.cn/desk/images/icon_credit_gary@2x.png',
                    ].map((src, index) => (
                      <img
                        key={index}
                        style={{
                          width: '32px',
                          height: '32px',
                          marginRight: '10px',
                        }}
                        src={src}
                        alt={`credit ${index}`}
                      />
                    ))}
                  </div>
                  <div className='credit-l-b'>
                    <div className='credit-l-b-l'>
                      <span className='credit-tag1'>{credit_score}</span>
                      <span className='credit-tag2'>{credit_title}</span>
                    </div>
                    <div className='credit-l-b-r'>
                      再接再厉，距离
                      <span className='col-FFEA05'>
                        社区大神级别还需节能 1 度
                      </span>
                    </div>
                  </div>
                </div>
                <div className='credit-r'>
                {
                  <img
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '25px',
                    }}
                    src={this.state.login_avatar}
                    alt='profile'
                  />
                }
                </div>
              </div>
            ) : (
              <div className='credit credit-nosignin credit-l'>
                <div>
                  {[
                    'https://cdn.axspace.cn/desk/images/icon_credit_full@2x.png',
                    'https://cdn.axspace.cn/desk/images/icon_credit_full@2x.png',
                    'https://cdn.axspace.cn/desk/images/icon_credit_full@2x.png',
                    'https://cdn.axspace.cn/desk/images/icon_credit_half@2x.png',
                    'https://cdn.axspace.cn/desk/images/icon_credit_gary@2x.png',
                  ].map((src, index) => (
                    <img
                      key={index}
                      style={{
                        width: '32px',
                        height: '32px',
                        marginRight: '10px',
                      }}
                      src={src}
                      alt={`credit ${index}`}
                    />
                  ))}
                </div>
                <div className='credit-l-b'>
                  <div className='credit-l-b-l'>
                    <span className='credit-tag1'>信用越高，权益越多</span>
                  </div>
                  <div className='credit-l-b-r'>
                    参与活动、助人为乐，轻松积攒信用，解锁更多专属福利
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='status'>
            <div className='alarm'>
              <div className='alarm-l'>
                <span className='spot'></span>
                <span
                  style={{
                    display: 'inline-block',
                    position: 'relative',
                    bottom: '1px',
                  }}
                >
                  无人值守自动安全报警：
                </span>
              </div>
              <div className='alarm-r'>启用中</div>
            </div>
            <div className='security'>
              <div
                className={security_all ? 'security-l' : 'security-l active'}
              >
                <div className='security-t'>
                  {security_all ? '安全' : '报警'}
                </div>
                <div className='security-b'>安防状态</div>
              </div>
              <div className='security-r'>
                <div className='security-t'>{space_human_count}</div>
                <div className='security-b'>当前空间人数</div>
              </div>
            </div>
            <div className='call-police'>
              <div
                className='water'
                style={{ width: '33%', display: 'inline-block' }}
              >
                <div>
                  <img
                    style={{ width: '70px', height: '56px' }}
                    src='https://cdn.axspace.cn/desk/images/pic_water@2x.png'
                    alt='water alarm'
                  />
                </div>
                <div className='call-police-c'>水浸报警</div>
                <div>
                  {water_safe ? (
                    <img
                      style={{ width: '62px', height: '26px' }}
                      src='https://cdn.axspace.cn/desk/images/btn_safe@2x.png'
                      alt='water active'
                    />
                  ) : (
                    <img
                      style={{ width: '62px', height: '26px' }}
                      src='https://cdn.axspace.cn/desk/images/btn_warning@2x.png'
                      alt='warning'
                    />
                  )}
                </div>
              </div>
              <div
                className='smoke'
                style={{ width: '33%', display: 'inline-block' }}
              >
                <div>
                  <img
                    style={{ width: '70px', height: '56px' }}
                    src='https://cdn.axspace.cn/desk/images/pic_smoke@2x.png'
                    alt='smoke alarm'
                  />
                </div>
                <div className='call-police-c'>烟感报警</div>
                <div>
                  {smoke_safe ? (
                    <img
                      style={{ width: '62px', height: '26px' }}
                      src='https://cdn.axspace.cn/desk/images/btn_safe@2x.png'
                      alt='smoke active'
                    />
                  ) : (
                    <img
                      style={{ width: '62px', height: '26px' }}
                      src='https://cdn.axspace.cn/desk/images/btn_warning@2x.png'
                      alt='warning'
                    />
                  )}
                </div>
              </div>
              <div
                className='safe'
                style={{ width: '33%', display: 'inline-block' }}
              >
                <div>
                  <img
                    style={{ width: '56px', height: '56px' }}
                    src='https://cdn.axspace.cn/desk/images/pic_safe@2x.png'
                    alt='safe alarm'
                  />
                </div>
                <div className='call-police-c'>安防报警</div>
                <div>
                  {security_safe ? (
                    <img
                      style={{ width: '62px', height: '26px' }}
                      src='https://cdn.axspace.cn/desk/images/btn_safe@2x.png'
                      alt='safe active'
                    />
                  ) : (
                    <img
                      style={{ width: '62px', height: '26px' }}
                      src='https://cdn.axspace.cn/desk/images/btn_warning@2x.png'
                      alt='warning'
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='main-t-r'>
            <div className='date'>{(new Date()).toISOString().slice(0, 10)}</div>
            <div className='score'>优</div>
            <div className='quality'>空气质量</div>
            <div className='temperature'>
              <div className='view-l'>
                <img
                  style={{
                    width: '16px',
                    height: '16px',
                    marginBottom: '8px',
                  }}
                  src='https://cdn.axspace.cn/desk/images/icon_temperature@2x.png'
                  alt='temperature'
                />
              </div>
              <div className='view-c'>温度</div>
              <div className='view-r'>
                <span>27</span>度
              </div>
            </div>
            <div className='humidity'>
              <div className='view-l'>
                <img
                  style={{
                    width: '16px',
                    height: '16px',
                    marginBottom: '8px',
                  }}
                  src='https://cdn.axspace.cn/desk/images/icon_humidity@2x.png'
                  alt='humidity'
                />
              </div>
              <div className='view-c'>湿度</div>
              <div className='view-r'>
                <span>27</span>%
              </div>
            </div>
            <div className='pm25'>
              <div className='view-l'>
                <img
                  style={{
                    width: '16px',
                    height: '16px',
                    marginBottom: '8px',
                  }}
                  src='https://cdn.axspace.cn/desk/images/icon_pm2.5@2x.png'
                  alt='pm2.5'
                />
              </div>
              <div className='view-c'>PM2.5</div>
              <div className='view-r'>22</div>
            </div>
            <div className='co2'>
              <div className='view-l'>
                <img
                  style={{
                    width: '16px',
                    height: '16px',
                    marginBottom: '8px',
                  }}
                  src='https://cdn.axspace.cn/desk/images/icon_co2@2x.png'
                  alt='co2'
                />
              </div>
              <div className='view-c'>CO2</div>
              <div className='view-r'>234</div>
            </div>
          </div>
        </div>
        <div className='main-c'>
          <div className='main-c-l'>
            <img
              style={{ width: '380px', height: '380px' }}
              src='https://cdn.axspace.cn/desk/images/video.gif'
              alt='video'
            />
          </div>
          <div className='main-c-r'>
            <div className='ml16 main-title'>
              {conversaion ? '小蓝' : '您可以问我：'}
            </div>

            {conversaion ? (
              <div className='info'>
                <div>
                  <div className='info-li li-received'>
                    您好，请问有什么可以帮助您的吗？
                  </div>
                </div>
                <div className='tar'>
                  <div className='info-li li-hair'>
                    帮我约一下明天中午的共享厨房
                  </div>
                </div>
                <div className='ad'>
                  <div className='info-li li-ad'>
                    <div className='ad-img'>
                      <img
                        style={{
                          width: '180px',
                          height: '100px',
                        }}
                        src='https://cdn.axspace.cn/desk/images/bg.png'
                        alt='ad'
                      />
                    </div>
                    <div className='ad-info'>
                      <div className='ad-title'>
                        <div className='ad-title1'>
                          <img
                            src='https://cdn.axspace.cn/desk/images/bg.png'
                            alt='ad icon'
                          />
                        </div>
                        <div className='ad-title2'>快来享受超市95折优惠</div>
                        <div className='ad-title3'>
                          <div className='ad-title3-text'>广告</div>
                        </div>
                      </div>
                      <div className='ad-data'>
                        免费办理建行信用卡，享受超市95折优惠，快来参加吧
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='info2'>
                <div className='intelligence'>
                  <span className='info-text col-efefef'>智能控制</span>
                  <span className='info-text'>家里现在多少度？</span>
                  <span className='info-text'>帮我打开日光节律照明</span>
                  <span className='info-text'>什么是日光节律照明？</span>
                  <span className='info-text'>
                    帮我设定一个清晨的灯光闹钟。
                  </span>
                  <span className='info-text'>
                    我困了，帮我设一个合适的灯光环境
                  </span>
                </div>
                <div className='house'>
                  <span className='info-text col-efefef'>租房住房</span>
                  <span className='info-text'>帮我推荐一个附近的公寓</span>
                  <span className='info-text'>
                    建行的公寓比其他公寓好在哪？
                  </span>
                  <span className='info-text'>
                    建行的住房租赁基金有什么特色？
                  </span>
                  <span className='info-text'>租赁住房市场未来什么趋势？</span>
                </div>
                <div className='reservation'>
                  <span className='info-text col-efefef'>共享空间预约</span>
                  <span className='info-text'>帮我约一下明晚的健身房</span>
                  <span className='info-text'>
                    帮我约一下明天中午的共享厨房
                  </span>
                </div>
              </div>
            )}
            <div className='main-btn' >
              <button className='btn-view' onClick={() => this.sttFromMic()}>
                <img
                  style={{
                    width: '20px',
                    height: '20px',
                    position: 'relative',
                    top: '5px',
                    marginRight: '8px',
                  }}
                  src='https://cdn.axspace.cn/desk/images/icon_microphone@2x.png'
                  alt='microphone'
                />
                {displayText}
              </button>
            </div>
          </div>
        </div>
        <div className='main-b'>
          <img
            style={{ width: '123px', height: '24px' }}
            src='https://cdn.axspace.cn/desk/images/logo.png'
            alt='logo'
          />
        </div>
      </div>
    );
  }
}

export async function getTokenOrRefresh() {
  const response = await fetch('/get_speech_token'); // 请根据需要替换为实际的端点
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  } else {
    const data = await response.json();
    return { authToken: data.token, region: data.region };
  }
}
