// var status = [
//     "init", 
//     "error", 
//     "ready", 
//     "recording", 
//     "recognize_success", 
//     "recognize_failed", 
//     "chat_to_server", 
//     "receving_response", 
//     "send_to_tts", 
//     "playing_audio"
// ]
import consumer from '../channels/consumer'
import React, { Component } from 'react';
import { ResultReason } from 'microsoft-cognitiveservices-speech-sdk';

const speechsdk = require('microsoft-cognitiveservices-speech-sdk')

export default class Talk extends Component {
    constructor(props) {
        super(props);
        this.btnRef = React.createRef();
        this.startRecording = this.startRecording.bind(this);
        this.stopRecording = this.stopRecording.bind(this);
        this.state = {
            status: "init",
            displayText: '努力加载中',
            simulatedText: ''  // 用于模拟语音输入的文本
        }
        this.simulateSpeechInput = this.simulateSpeechInput.bind(this);
    }
    
    async componentDidMount() {
        this.tokenObj = await getTokenOrRefresh();
        window.talkComponent = this;
        window.addEventListener('keydown', this.handleKeyDown);
        window.handleRecognizedText = this.simulateSpeechInput.bind(window.talkComponent);
        window.setStateReady = this.setStateReady.bind(this);

        window.handleNoMatch = function() {
            console.log("No speech recognized.");
            this.setState({
                displayText: '没听清楚，请再说一遍',
                status: "recognize_failed"
            });
        }.bind(window.talkComponent);
        
        window.handleCanceled = function(errorDetails) {
            console.log("Recognition canceled:", errorDetails);
            // Handle cancellation
        }.bind(window.talkComponent);

        const roomIdElement = document.getElementById('room_id');
        const roomId = roomIdElement ? roomIdElement.textContent : null;
        this.roomId = roomId;
        if (this.tokenObj.authToken === null) {
            this.setState({
                displayText: "出错了，请重新进入页面试试",
                status: "error"
            });
        } else {
            this.setState({
                displayText: '按一下说话',
                status: "ready",
            });
        }
        this.cable = consumer;
        this.subscription = this.cable.subscriptions.create(
            { channel: 'RoomMessageChannel', room_id: this.roomId },
            { received: this.roomMessageListener.bind(this) }
        );
        // 初始化 Pusher
        Pusher.logToConsole = true; // 可以在控制台查看日志，便于调试
        var pusher = new Pusher("ceea1a93623f02506d45", {
            cluster: "ap3",
            encrypted: true,
            // authEndpoint: 'http://localhost:3000/pusher_auth'
            authEndpoint: 'https://voice.axspace.cn/pusher_auth'
        });
        

        // 订阅私有通道
        var channel = pusher.subscribe('private-my-channel');
        let pusherData = {}

        // 订阅 channel 的消息
        channel.bind("client-message", (data) => {
            console.log(`<< [${data.id}] ${data.message}`);
            pusherData=data
            console.log('pusherData',data)
            let eventNameNo = "client-message-no";
            let dataNo = {message:`不支持此操作`}
            if (data.type == 'voice-start') {
                this.startRecording();
                dataNo = {message:`执行成功`}
            } else if (data.type == 'voice-stop') {
                this.stopRecording();
                dataNo = {message:`执行成功`}
            } else if (data.type == 'voice-reload') {
                window.location.reload();
                dataNo = {message:`执行成功`}
            } else if (data.type == 'voice-jump') {
                window.location.href = "http://tsingarchitech.com/plat";
                dataNo = {message:`执行成功`}
            }
            channel.trigger(eventNameNo, dataNo);
            
        });
    }
    handleKeyDown = (event) => {
        if (event.key === 's') {
            this.startRecording();
        } else if (event.key === 'e') {
            this.stopRecording();
        } else if (event.key === 'b') {
            window.location.href = "http://tsingarchitech.com/plat";
        } else if (event.key === 'r') {
            window.location.href = "http://voice.axspace.cn/guest0922";
        } else if (event.key === 'q') {
            this.playMutedAudio();
        }
    }
        
    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
        this.cable.subscriptions.remove(this.subscription);
    }
    
    roomMessageListener(data) {
        console.log(data)
        if (data.type == 'update_text') {
            this.content = data.content
            if (this.content.length == 0) {
                return
            }
            window.document.getElementById('talk-author-name').innerHTML = data.user;
            window.document.getElementById('talk-content').innerHTML = data.content;

            // Set status only when first received
            if (this.state.status !== 'send_to_tts') {
                this.setState({
                    status: 'send_to_tts',
                    displayText: "合成中..."
                });
            }

            // If Android interface is available, send text to Android
            if (typeof Android !== 'undefined' && Android.sendTextToAndroid) {
                Android.sendTextToAndroid(this.content);
            } else {
                // Web page plays sound
                if (data.final == true) {
                    this.send_to_tts(this.content);
                }
            }

        } else {
            console.log('unknown type in roomMessageListener')
        }
    }

    simulateSpeechInput(text) {
        console.log('Simulate speech input:', text);
        this.setState({ simulatedText: text }, () => {
            // 调用识别逻辑来处理模拟输入
            this.handleSimulatedSpeech();
        });
    }

    // 处理模拟语音输入
    handleSimulatedSpeech() {
        const result = {
            reason: ResultReason.RecognizedSpeech, // 强制设置为识别成功
            text: this.state.simulatedText
        };
        
        let displayText = `收到：${result.text}`;
        console.log(displayText);

        this.setState({
            displayText: displayText,
            status: "recognize_success"
        });

        // 操作 DOM，将识别到的文本显示到页面上
        window.document.getElementById('talk-author-name').innerHTML = '用户';
        window.document.getElementById('talk-content').innerHTML = result.text;

        this.sendTextToServer(result.text);
    }

    async send_to_tts(content) {
        this.init_synthesizer();
        this.synthesizer.speakTextAsync(
          content,
          result => {
            if (result.reason === speechsdk.ResultReason.SynthesizingAudioCompleted) {
                window.playVideoTalk();
                console.log("Synthesis finished for [" + content + "].");
                let source2 = this.audioContext.createBufferSource();
                this.audioContext.decodeAudioData(result.audioData, (buffer) => {
                source2.buffer = buffer;
                source2.connect(this.audioContext.destination);
                this.silenceSource.onended = () => {
                    source2.start(0);
                    this.setState({ 
                        status: 'playing_audio', 
                        displayText: "讲话中..."
                    });
                };
                
                source2.onended = () => {
                    this.setState({ 
                        status: 'ready', 
                        displayText: "按一下说话"
                    });
                    window.playVideoMute();
                    this.playMutedAudio()
                };
            });
            } else if (result.reason === speechsdk.ResultReason.Canceled) {
              console.log("Synthesis failed. Error detail: " + result.errorDetails);
            }
          },
          err => {
            console.log("Error: " + err);
          }
        );
    }

    testTTS() {
        if (typeof Android !== 'undefined' && Android.sendTextToAndroid) {
            Android.sendTextToAndroid("你好");
        } else {
            console.log("Android interface not available.");
            // 如果 Android 接口不可用，可以使用网页的 TTS 进行测试
            this.send_to_tts("你好");
        }
    }

    playMutedAudio() {
        // 如果在安卓里，这个不该被调用
        if (typeof Android !== 'undefined') {
            console.log("playMutedAudio should not be called in Android.");
            return
        }
        if (this.audioContext === undefined) {
            this.audioContext = new AudioContext();
        }

            // 如果 audioContext 是 suspended 状态，尝试恢复
        if (this.audioContext.state === 'suspended') {
            this.audioContext.resume();
        }
        
        this.silenceSource = this.audioContext.createBufferSource();
        let silenceBuffer = this.audioContext.createBuffer(1, this.audioContext.sampleRate * 0.2, this.audioContext.sampleRate); // 创建一个无声buffer
        
        // 开始播放无声音频
        this.silenceSource.buffer = silenceBuffer;
        this.silenceSource.connect(this.audioContext.destination);
        this.silenceSource.start(0);
        this.silenceSource.onended = () => {
            this.playMutedAudio()
        };
    }

    // 老的识别逻辑
    startRecordingOld() {
        if(this.state.status == "ready") {
            console.log("mouse down - startRecording");
            this.sttFromMic();
        } else {
            console.log("ignore dup startRecording")
        }
    }

    stopRecordingOld() {
        if (this.state.status == "recording") {
            console.log("stopRecording");
            this.recognizer.stopContinuousRecognitionAsync();
            this.setState({ 
                status: 'ready', 
                displayText: "按一下说话"
            });
        }
    }

    startRecording() {
        if (this.state.status === "ready") {
            console.log("Start recording");
            if (typeof Android !== 'undefined' && Android.startRecording) {
                Android.startRecording();
                this.setState({
                    status: "recording",
                    displayText: '聆听中，请说话'
                });
            } else {
                console.log("Android interface not available.");
                this.startRecordingOld();
            }
        } else {
            console.log("Recording already in progress or not ready.");
        }
    }
    
    stopRecording() {
        if (this.state.status === "recording") {
            console.log("Stop recording");
            if (typeof Android !== 'undefined' && Android.stopRecording) {
                Android.stopRecording();
                this.setState({
                    status: "ready",
                    displayText: "按一下说话"
                });
            } else {
                console.log("Android interface not available.");
                this.stopRecordingOld();
            }
        }
    }

    setStateReady() {
        this.setState({
            status: 'ready',
            displayText: "按一下说话"
        });
    }

    init_synthesizer() {
        if (this.synthesizer === undefined) {
            if (this.speechConfig === undefined) {
                this.speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(this.tokenObj.authToken, this.tokenObj.region);
                this.speechConfig.speechRecognitionLanguage = 'zh-CN';
                this.speechConfig.speechSynthesisVoiceName = "zh-CN-XiaoxiaoNeural";
            }
            let stream = speechsdk.AudioOutputStream.createPullStream();
            const synthesizerAudioConfig = speechsdk.AudioConfig.fromStreamOutput(stream);
            this.synthesizer = new speechsdk.SpeechSynthesizer(this.speechConfig, synthesizerAudioConfig);
        }
    }

    init_recognizer() {
        if (this.speechConfig === undefined) {
            this.speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(this.tokenObj.authToken, this.tokenObj.region);
            this.speechConfig.speechRecognitionLanguage = 'zh-CN';
            this.speechConfig.speechSynthesisVoiceName = "zh-CN-XiaoxiaoNeural";
        }
        const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
        this.recognizer = new speechsdk.SpeechRecognizer(this.speechConfig, audioConfig);
    }

    async sttFromMic() {
        this.playMutedAudio();
        // 创建语音合成器对象
       
        this.init_recognizer()
        console.log('set recognizer');
        this.setState({
            status: "recording",
            displayText: '聆听中，请说话'
        });
        this.recognizer.startContinuousRecognitionAsync();
        this.recognizer.recognized = (s, e) => {
            let displayText;
            let status;
            let result = e.result;
            console.log(result);
            if (result.reason === ResultReason.RecognizedSpeech) {
                if (this.state.status != "recording") {
                    console.log("speech received not in recording, ignore it.");
                    return;
                }
                displayText = `收到：${result.text}`
                console.log(displayText);
                status = "recognize_success"
                // 操纵 DOM，把 ID = talk-author-name 的innerHTML改成 result.text
                window.document.getElementById('talk-author-name').innerHTML = '用户';
                window.document.getElementById('talk-content').innerHTML = result.text;
                this.sendTextToServer(result.text);
                this.recognizer.stopContinuousRecognitionAsync();
            } 
            // else {
            //     displayText = '没听清楚，请再说一遍';
            //     status = "recognize_failed"
            //     this.setState({
            //         displayText: displayText,
            //         status: status
            //     });
            //     this.sttFromMic();
            // }
        
        };
        return;
        recognizer.recognizeOnceAsync(result => {
            let displayText;
            let status;
            if (result.reason === ResultReason.RecognizedSpeech) {
                displayText = `收到：${result.text}`
                status = "recognize_success"
                this.setState({
                    displayText: displayText,
                    status: status
                });
                this.sendTextToServer(result.text);
            } else {
                displayText = '没听清楚，请再说一遍';
                status = "recognize_failed"
                this.setState({
                    displayText: displayText,
                    status: status
                });
                this.sttFromMic();
            }
        });
    }

    async sendTextToServer(text) {
        this.setState({
            displayText: "思考中...",
            status: "chat_to_server"
        });
        const roomId = this.roomId
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    
        const params = new URLSearchParams();
        params.append('authenticity_token', csrfToken);
        params.append('message[content]', text);
    
        const response = await fetch(`/rooms/${roomId}/messages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'X-CSRF-Token': csrfToken
            },
            body: params.toString(),
            credentials: 'include' // Important for sending cookies
        });
    
        if (!response.ok) {
            // throw new Error(`HTTP error! status: ${response.status}`);
            this.setState({
                displayText: "出错了，请重新进入页面试试",
                status: "error"
            });
        } else {
            this.setState({
                displayText: "思考中...",
                status: "receving_response"
            });
        }
    }

    shouldShowText() {
        return this.state.status == "init" || 
        this.state.status == "error" || 
        this.state.status == "recognize_success" || 
        this.state.status == "recognize_failed" || 
        this.state.status == "chat_to_server" || 
        this.state.status == "receving_response" || 
        this.state.status == "send_to_tts" ||
        this.state.status == "playing_audio"
    }

    shouldShowButton() {
        // return true
        //     "ready", 
        return this.state.status == "ready" || this.state.status == "recording"
    }
    
    showHideList() {
        const showStatuses = ["recording", "recognize_success", "recognize_failed", "chat_to_server", "receving_response", "send_to_tts", "playing_audio"];

        if (showStatuses.includes(this.state.status)) {
            if (window.showTalkList) {
                window.showTalkList();
            }
        } else {
            if (window.hideTalkList) {
                window.hideTalkList();
            }
        }        
    }

    render() {
        if (this.props.desk) {
            this.showHideList();
            return (
                <div className="bottom-b">
                    <div className="weui-bottom-fixed-opr">
                        <span className="weui-loadmore__tips" onMouseDown={() => this.startRecording()}>{this.state.displayText}</span>
                        {/* <button className="weui-btn weui-btn_default" onClick={this.testTTS} style={{ marginLeft: '10px' }}>
                            测试 TTS
                        </button> */}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="weui-bottom-fixed-opr-page__tool">
                    {this.shouldShowText() &&                 
                    <div className="weui-loadmore">
                        <i className="weui-loading"></i>
                        <span className="weui-loadmore__tips">{this.state.displayText}</span>
                    </div>}
                    {this.shouldShowButton() && 
                        <div className="weui-bottom-fixed-opr">
                            <button className="weui-btn weui-btn_primary" onClick={() => this.sttFromMic()}>
                                按一下 说话
                            </button>
                            {/* <button className="weui-btn weui-btn_default" onClick={this.testTTS} style={{ marginLeft: '10px' }}>
                                测试 TTS
                            </button> */}
                        </div>
                    }
                </div>
            );
        }
    }    
}

export async function getTokenOrRefresh() {
    const response = await fetch('/get_speech_token'); // Replace with the actual endpoint if needed
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    } else {
        const data = await response.json();
        return { authToken: data.token, region: data.region };
    }
}